import React, { useEffect } from 'react'

import '../assets/css/Home.css';
import AboutVision from '../assets/image/vision.svg';
import AboutMision from '../assets/image/mision.svg';
import Button from '../components/Button';
import ReviewSlide from '../components/ReviewSlide';
import BannerSlider from '../components/Banner-slider';
import { Helmet } from 'react-helmet-async';

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <Helmet>
        <title>FlickNLinK - About</title>
        <meta
          name="description"
          content="Learn more about FlickNLinK - How our NFC business cards revolutionize networking with digital and contactless solutions."
        />
        <meta
          name="keywords"
          content="FlickNLinK, About, NFC business cards, digital identity, networking solutions, smart cards"
        />
        <meta property="og:title" content="About FlickNLinK" />
        <meta property="og:description" content="Discover how FlickNLinK's NFC cards provide seamless networking solutions with instant connections and digital profiles." />
        <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
        <meta property="og:url" content="https://flicknlinK.com/about" />
        <meta property="og:type" content="website" />
      </Helmet>
    <section className='banner bg-banner bg-no-repeat bg-contain bg-bottom'>
        <div className='container'>
          <div className='grid lg:grid-cols-2 grid-cols-1 lg:gap-5 gap-10 pb-1'>
            <div>
              <h1>About Connect</h1>
              <p>At FlickNLinK, we are redefining the future of professional networking. By combining cutting-edge technology with eco-conscious practices, we offer QR codes and NFC-enabled business cards that leave a lasting impression while reducing paper waste.</p>
              <div className='flex justify-start items-stretch gap-5 md:mt-10 mt-5'>
                <Button link='/contact' className='dark-btn' name="Contact Us" />
              </div>
            </div>
            <div className='banner-slider'>
              <BannerSlider/>
            </div>
          </div>
        </div>
      </section>
      <section className='about'>
        <div className='container'>
          <div className='grid lg:grid-cols-2 grid-cols-1 items-center lg:gap-5 gap-10'>
            <div>
              <h2>Our Mission</h2>
              <p>We envision a world where professional connections are made effortlessly and sustainably. Through our digital profiles and smart cards, we empower individuals to showcase the depth of their professional identities. FlickNLinK is about more than just exchanging contact details—it’s about creating meaningful relationships and embracing the future of communication.</p>
            </div>
            <div>
              <img className='max-w-[300px] mx-auto' src={AboutMision} alt='SlideFram' />
            </div>
          </div>
        </div>
      </section>
      <section className='about'>
        <div className='container'>
          <div className='grid lg:grid-cols-2 grid-cols-1 items-center lg:gap-5 gap-10'>
            <div className='order-2 lg:order-1'>
              <img className='max-w-[300px] mx-auto' src={AboutVision} alt='SlideFram' />
            </div>
            <div className='order-1 lg:order-2'>
              <h2>Our Values</h2>
              <ul>
              <p>At FlickNLinK, we are committed to:</p>
                <li><strong>Sustainability:</strong> Reducing waste and promoting eco-friendly practices.</li>
                <li><strong>Innovation:</strong>  Continuously developing cutting-edge solutions to meet the evolving needs of our users.</li>
                <li><strong>Efficiency:</strong> Streamlining professional networking processes and saving time.</li>
                <li><strong>Accessibility:</strong> Ensuring our products are user-friendly and accessible to all.</li>
                <li><strong>Impact:</strong> Making a positive contribution to the business community and the environment.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <ReviewSlide />
    </>
  )
}

export default About