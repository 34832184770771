import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async';

function ShippingPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title>FlickNLinK - Shipping Policy</title>
                <meta
                name="description"
                content="Read FlickNLinK's shipping policy for NFC business cards, including delivery options, timelines, and charges."
                />
                <meta
                name="keywords"
                content="FlickNLinK, shipping policy, NFC business card delivery, shipping charges, delivery times"
                />
                <meta property="og:title" content="FlickNLinK - Shipping Policy" />
                <meta property="og:description" content="Learn more about FlickNLinK's shipping policy for NFC business cards, including delivery charges, timelines, and processes." />
                <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
                <meta property="og:url" content="https://flicknlinK.com/shipping-policy" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className='container policy'>
                <h1>Shipping Policy</h1>

                <p>At FlickNLinK, we aim to deliver your customizable digital products promptly. The production process typically takes two business days, and shipping times range from 4 to 7 business days, depending on your location.</p>

                <h4>Delivery times are as follows:</h4>
                <p>Gujarat: 3-4 business days</p>
                <p>Once your order has shipped, you can track it via the link provided in your confirmation email for real-time updates on its status.</p>
            </div>
        </>
    )
}

export default ShippingPolicy
