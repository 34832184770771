import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';

import Chevron from '../assets/image/chevron-right.svg';
import LogoWhite from '../assets/image/LogoWhite.png';
import axios from 'axios';
import Swal from "sweetalert2";

function Footer() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const [formData, setFormData] = useState({
        email: ''
    });
    
    const [errors, setErrors] = useState({});
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
     };
    
    const validateForm = () => {
        const newErrors = {};
        
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email address is invalid';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            axios.post('https://digiadmin.acrodapt.com/api/newsletter',formData)
            .then(response => {
                if(response.data === 'success') {
                    Swal.fire({
                      title: "Success!",
                      text: "Your mail added successfully",
                      icon: "success",
                      confirmButtonText: "OK",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        setFormData({
                            email : ''
                        });
                      }
                    });
                } else if(response.data === 'fail') {
                    Swal.fire({
                      title: "Fail!",
                      text: "There are some issue",
                      icon: "error",
                      confirmButtonText: "OK",
                    });
                }
            }).catch(err => {
            });
        }
    };
    return (
        <>
            <footer>
                <div className='container'>
                    <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-4 gap-10'>
                        <div>
                        <Link to='/' className="logo">
                            <img src={LogoWhite} alt='LogoWhite' />
                        </Link>
                            <ul>
                                <li>
                                    <Link to="mailto:flicknlink@gmail.com">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                        </svg>
                                        flicknlink@gmail.com
                                    </Link>
                                </li>
                                <li>
                                    <Link to="tel:+917016555196">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                                        </svg>
                                        +91 7016555196
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h5>Quick Link</h5>
                            <ul>
                                <li><Link to='/'> <img src={Chevron} alt='chevron' /> Home</Link></li>
                                <li><Link to='/about'> <img src={Chevron} alt='chevron' /> About Us</Link></li>
                                <li><Link to='/feature'> <img src={Chevron} alt='chevron' /> Feature</Link></li>
                                <li><Link to='/pricing'> <img src={Chevron} alt='chevron' /> Pricing</Link></li>
                                <li><Link to='/review'> <img src={Chevron} alt='chevron' /> Review</Link></li>
                                <li><Link to='/contact'> <img src={Chevron} alt='chevron' /> Contact</Link></li>
                            </ul>
                        </div>
                        <div>
                            <h5>Policy's</h5>
                            <ul>
                                <li><Link to='/subscriptionpolicy'> <img src={Chevron} alt='chevron' /> Subscription Policy</Link></li>
                                <li><Link to='/shippingpolicy'> <img src={Chevron} alt='chevron' /> Shipping Policy</Link></li>
                                <li><Link to='/refundpolicy'> <img src={Chevron} alt='chevron' /> Refund Policy</Link></li>
                                {/* <li><Link> <img src={Chevron} alt='chevron' /> Professional Theams</Link></li>
                                <li><Link> <img src={Chevron} alt='chevron' /> New Theams</Link></li>
                                <li><Link> <img src={Chevron} alt='chevron' /> Avengers Edition</Link></li> */}
                            </ul>
                        </div>
                        <div>
                            <h5>Newsletter</h5>
                            <p>Subscribe to our newsletter to stay updated on the latest news, important announcements, and special offers from FlickNLinK.</p>
                            <div className='newsletter'>
                                <input placeholder='Enter Your Email' 
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <div onClick={handleSubmit}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send" viewBox="0 0 16 16">
                                        <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                                    </svg>
                                </div>
                            </div>
                            {errors.email && <span className="error-message">{errors.email}</span>}
                        </div>
                    </div>
                    <hr />
                    <div className='copy-right'>
                        <div>
                            <Link>Copyright © 2024 FlickNLinK All Right Reserved.</Link>
                        </div>
                        <div className='copy-links'>
                            <Link to='/terms'>Terms</Link>
                            <span>|</span>
                            <Link to='/privacypolicy'>Privacy</Link>
                            {/* <span>|</span>
                            <Link>Security</Link> */}
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;